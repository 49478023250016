<template>
  <page-container>
    <div class="project-container">
      <video
        v-if="project.video"
        :src="video"
        class="hero"
        :class="{ pos: project.name.startsWith('Spartan') }"
        loading="lazy"
        autoplay
        muted
        loop
      ></video>

      <div
        class="hero"
        :style="{
          backgroundImage: `url(${coverImage}`,
        }"
        v-else
      ></div>

      <h4 class="title">{{ project.name }}</h4>

      <p class="description">
        {{ project.intro }}
      </p>

      <div class="services">
        <div class="header">
          <h3 class="header-title">Services</h3>
          <div class="header-background" :class="project.class"></div>
        </div>
        <p
          class="service"
          v-for="(service, index) in project.services"
          :key="index"
        >
          {{ service }}
        </p>
      </div>

      <div class="project-image-container">
        <img
          :src="logoImage"
          class="project-image"
          id="img-1"
          alt="project-image"
        />
        <div class="cover-top"></div>
        <div class="cover-bottom"></div>
      </div>

      <p class="description">
        {{ project.brief[0] }}
      </p>

      <image-slider v-if="skinnyMobile" :project="project"></image-slider>

      <carousel
        :project="project"
        :numImages="project.carousel.length"
        v-else
      ></carousel>

      <p class="description mg-med">
        {{ project.brief[1] }}
      </p>

      <scroll-top></scroll-top>
    </div>
  </page-container>
</template>

<script>
import gsap from 'gsap';
import { ScrollTrigger } from 'gsap/ScrollTrigger';
import { mapGetters } from 'vuex';
import Carousel from '@/components/Carousel.vue';
import ImageSlider from '@/components/ImageSlider.vue';

gsap.registerPlugin(ScrollTrigger);
export default {
  props: ['projectId'],

  data() {
    return {
      skinnyMobile: false,
    };
  },

  components: {
    Carousel,
    ImageSlider,
  },

  computed: {
    project() {
      return this.PROJECTS().find((el) => el.name === this.projectId);
    },

    numImages() {
      return this.project.images.length - 1;
    },

    showCarousel() {
      return this.numImages > 1;
    },

    coverImage() {
      if (this.skinnyMobile) {
        return this.project.cover.mobile.story;
      }
      return this.project.cover.desktop;
    },

    video() {
      if (this.skinnyMobile) {
        return this.project.video.mobile;
      }
      return this.project.video.desktop;
    },

    logoImage() {
      if (this.skinnyMobile) {
        return this.project.images[0].mobile;
      }
      return this.project.images[0].desktop;
    },
  },

  methods: {
    ...mapGetters(['PROJECTS']),

    scroll(num) {
      // 1. Remove active form from all btns
      const btns = [...this.$refs.selector.children];
      btns.forEach((el) => {
        el.classList.remove('active');
      });

      // // 2. Calculate scroll distance according to the btn clicked
      --num;
      const scroll = num * 100;

      // // 3. Add active form to current slide btn and navigate to selected project
      this.$refs.carousel.style.transform = `translateY(-${scroll}vh)`;
      btns[num].classList.add('active');
    },

    checkScreen() {
      const windowWidth = window.innerWidth;
      if (windowWidth <= 500) {
        this.skinnyMobile = true;
        return;
      }
      return (this.skinnyMobile = false);
    },
  },

  mounted() {
    gsap.to(['.cover-top', '.cover-bottom'], {
      width: 0,
      duration: 1.8,
      scrollTrigger: {
        id: 'trigger1',
        trigger: '#img-1',
        start: '42.5% 95%',
      },
    });
  },

  created() {
    this.checkScreen();
    window.addEventListener('resize', this.checkScreen);
  },
};
</script>

<style lang="scss" scoped>
.project-container {
  .carousel-container {
    width: 100%;
    height: 100vh;
    margin-bottom: 8.3rem;
    overflow-y: hidden;
    scrollbar-width: none;
    position: relative;

    .carousel {
      width: 100%;
      height: 500%;
      transition: all 0.5s ease;

      .image-container {
        width: 100%;
        height: 20%;
        position: relative;
      }

      &-image {
        width: 100%;
        height: 100%;
        object-fit: cover;
        object-position: center;
        margin: 0;
        padding: 0;
      }

      .credit {
        position: absolute;
        bottom: 0;
        left: 0;
      }
    }
  }

  .top {
    bottom: 5rem !important;
  }
}

.pos {
  object-position: bottom;
}
</style>